/* AboutMe.css */
.about-me {
  padding: 20px;
  padding-bottom: 120px; /* Add padding to the bottom to accommodate the footer */
}

.section {
  margin-bottom: 20px;
}

h3 {
  margin-bottom: 10px;
}

ul {
  list-style-type: disc;
  margin-left: 20px;
}

li {
  margin-bottom: 5px;
}

.about-me,
.section,
h3,
ul,
li {
  color: #3e2723; /* Dark brown text color */
  font-weight: bolder;
}

/* CSS */

/* For webkit browsers (Google Chrome, Safari, etc.) */
::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
}

/* Track */
::-webkit-scrollbar-track {
  background: #e7c09f; /* Color of the scrollbar track */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #2e2a24; /* Color of the scrollbar handle */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #3e2723; /* Color of the scrollbar handle on hover */
}
